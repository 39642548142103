<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="salesProspecting"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id })" :style="{ color: getAccountNameColor(item) }">
                                    {{ item.sp_primary_details.account_name }}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <v-btn
                                    text
                                    color="primary"
                                    @click="approveDialog = true , id_to_revert = item.id"
                                    >
                                    <v-icon>mdi-backup-restore</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-dialog
                v-model="approveDialog"
                persistent
                max-width="400"
                >
                <v-card>
                    <v-card-title class="mb-1">
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p style="font-size: 15px;"><b>Revert Status? You can't undo this.</b></p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="approveDialog = false"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="revertStatus()"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'

export default {
  name: 'Users',
  data () {
    return {
        search: '',
        salesProspecting: [],
        initialStatus: ['pending','Scorecard Failed','Inital Evaluation Committee Approval Pending','Inital Evaluation Committee Approval Pending','Initial Evaluation Committee Approved','existing','Not accepted','Pending (Presentation)','For negotiation','Accepts Partnership','Evaluation (Returned)','Archived','Evaluation (Hanging)','Initial Evaluation Committee Approval Pending'],
        finalStatus: ['Initial Evaluation Complete','Onboarding Approval Pending','Onboarding Approved','Initial Existing Complete','Existing Complete','Existing (Validation Approval Pending)','Onboarding (Returned)','Validation (Returned)','Onboarding (Hanging)','Merged (IS)','Existing Complete (IS)'],
        headers: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'BP ID Number',
                sortable: true,
                value: 'bp_idno'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Date Created',
                sortable: true,
                value: 'created_at'
            },
            {
                text: 'Profile',
                sortable: true,
                value: 'profile'
            },
            {
                text: 'Approval Status',
                sortable: true,
                value: 'status'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        greenStatus: ['Initial Evaluation Complete','Onboarding Approved','Initial Existing Complete','Existing Complete','Merged (IS)','Existing Complete (IS)'],
        orangeStatus: ['pending','Inital Evaluation Committee Approval Pending','Initial Evaluation Committee Approved','Onboarding Approval Pending','existing','Pending (Presentation)','For negotiation','Accepts Partnership','Evaluation (Returned)','Termination Pending','Deletion Pending','Initial Evaluation Committee Approval Pending','Onboarding (Returned)','Validation (Returned)','Existing (Validation Approval Pending)'],
        redStatus: ['Scorecard Failed','Not accepted','Archived','Existing (Temporarily Terminated)','Terminated','Evaluation (Hanging)'],
        approveDialog: false,
        id_to_revert: null
    }
  },
  computed: {
    getAccountNameColor() {
        return (item) => {
            if (this.greenStatus.includes(item.status)) {
                return 'green'
            } else if (this.orangeStatus.includes(item.status)) {
                return 'orange'
            } else if (this.redStatus.includes(item.status)) {
                return 'red'
            } 
            else {
                return 'black'
            }
        }
    },
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    const res = await this.$store.dispatch('salesProspecting/doGetAllListSalesProspecting')

    res.data.result.map( e => {
        if (e.status == 'Existing Complete' || e.status == 'Onboarding Approved'){
            this.salesProspecting.push(e)
        }
    })

    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM')
    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM_INITIAL_UPLOAD')
    this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_OFFICER_DEFAULT')
    this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGFILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_FILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_BRANCH_PERMIT_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_OWNER_AMLA_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_FINAL_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BRANCH_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BSP_DEFAULT')
    this.$store.commit('salesProspecting/SET_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BOD_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_DEFAULT')
    this.$store.commit('bsp/UNSET_BSP_ID')
    this.$store.commit('bsp/SET_DET_DETAILS_DEFAULT')
    this.$store.commit('bsp/SET_DEF_FILES_DEFAULT')
    this.$store.commit('bsp/DET_FILES_DEFAULT')
    this.$store.commit('bsp/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_ENCODER_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_APPROVED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_INITIALCOMMENT_DEFAULT')
    this.$store.commit('bsp/SET_COMPLIANCEUPLOAD_DEFAULT')
    this.$store.commit('salesProspecting/SET_RADIO_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_CHECKBOX_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOTHERENTITY_DETAILS_DEFAULT')
  },
  methods: {
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
    async revertStatus(){
        const res = await this.$store.dispatch('salesProspecting/doRevertStatus', this.id_to_revert)
        if (res.status == 200){
            this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'BP Status Reverted Successfully!','messagetype':'success'});
            location.reload();
        } else {
            this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Something went wrong!','messagetype':'error'});
        }
    }
  }
}
</script>

<style scoped>
.initial-status {
  color: blue;
}

.final-status {
  color: green;
}
</style>